import React from "react";
import Typewriter from "typewriter-effect";

import "./CopyWriting.css";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";
import copywriting from "../image/copywritting.jpg";
const CopyWriting = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row SMM-div">
          <div className="col-lg-3">
            {/* <div className="mt-5 return-link">
              <Link to="/" className="servicelinks ">
                Home
              </Link>
              <span>/CopyWriting</span>
            </div> */}
          </div>
          <div className="col-lg-6  smm-banner">
            <div id="app" className="mt-5">
              <Typewriter
                options={{
                  loop: true,
                }}
                onInit={(typewriter) => {
                  typewriter
                    .typeString("COPY WRITING")
                    .pauseFor(2500)
                    .deleteAll()

                    .start();
                }}
              />
            </div>
          </div>
          <div className="col-lg-3"></div>
        </div>
      </div>
      <div className="container-fluid service-container">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <img
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
                className="static-image mt-5 zoom-on-hover rounded-circle brand-image"
                src={copywriting}
                alt="image"
                height={400}
                width={400}
              />
            </div>
            <div className="col-lg-6 mt-3">
              <h6
                className="seo-head mt-4"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                COPY WRITTING HELP TO YOUR BUSNESS GROW
              </h6>
              <h2
                className="mt-2"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                COPY WRITING
              </h2>
              <p
                className="web-text"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                Copywriting is the craft of creating persuasive and compelling
                text designed to engage an audience and drive them to take
                specific actions, such as making a purchase or subscribing to a
                service. Effective copywriting combines creativity with a deep
                understanding of the target audience, focusing on clear
                messaging, strong calls to action, and emotional appeal. It's
                essential for marketing, advertising, and content creation,
                helping businesses communicate their value propositions, build
                brand awareness, and boost conversions.
              </p>
              <p
                className="web-text"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                Copywriting involves writing text that persuades and motivates
                readers to take desired actions, such as buying a product or
                signing up for a newsletter. It requires a blend of creativity
                and marketing knowledge, aiming to capture the audience's
                attention and convey key messages clearly and compellingly.
              </p>
            </div>
          </div>

          <div className="row mt-3">
            <h2
              className="seo-head text-center"
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="100"
            >
              TYPES OF COPY WRITING
            </h2>
            <div
              className="col-lg-12 d-flex justify-content-center gap-5  product-list "
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="100"
            >
              <div className="mt-3">
                <div className="d-flex gap-2 align-items-center ">
                  <i class="fa-regular fa-circle-check"></i>{" "}
                  <h5 className="mt-1">SEO Copywriting</h5>
                </div>
                <div className="d-flex gap-2 align-items-center mt-3 ">
                  <i class="fa-regular fa-circle-check"></i>{" "}
                  <h5 className="mt-1">Sales Copywriting</h5>
                </div>
                <div className="d-flex gap-2 align-items-center mt-3 ">
                  <i class="fa-regular fa-circle-check"></i>{" "}
                  <h5 className="mt-1">Content Copywriting</h5>
                </div>
              </div>

              <div className="mt-3">
                <div className="d-flex gap-2 align-items-center ">
                  <i class="fa-regular fa-circle-check"></i>{" "}
                  <h5 className="mt-1">Social Media Copywriting</h5>
                </div>
                <div className="d-flex gap-2 align-items-center  mt-3 ">
                  <i class="fa-regular fa-circle-check"></i>{" "}
                  <h5 className="mt-1">Technical Copywriting</h5>
                </div>
                <div className="d-flex gap-2 align-items-center mt-3 ">
                  <i class="fa-regular fa-circle-check"></i>{" "}
                  <h5 className="mt-1">Email Copywriting</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-12">
              <h2
                className="text-center web-head"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                How Copywriting Helps Your Business
              </h2>
              <p
                className="text-center mt-3 web-text"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                <span className="copywriting-text">Boosts Sales: </span>{" "}
                Persuasive and compelling copy drives conversions by effectively
                communicating the value of your products or services, leading to
                increased sales.
              </p>
              <p
                className="text-center mt-3 web-text"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                <span className="copywriting-text">Enhances Brand Image: </span>
                Well-crafted copy establishes a consistent and professional
                brand voice, helping to build trust and credibility with your
                audience.
              </p>
              <p
                className="text-center mt-3 web-text"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                <span className="copywriting-text">Improves SEO: </span>
                SEO-focused copywriting increases your website's visibility on
                search engines, driving more organic traffic and potential
                customers to your site.
              </p>
              <p
                className="text-center mt-3 web-text"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                <span className="copywriting-text">Engages Customers: </span>
                Engaging and relevant content keeps your audience interested and
                encourages them to interact with your brand, fostering loyalty
                and repeat business.
              </p>
              <p
                className="text-center mt-3 web-text"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                <span className="copywriting-text">Educates Audience:: </span>
                Informative copy provides valuable information to your
                customers, helping them make informed decisions and positioning
                your business as an industry expert.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CopyWriting;
