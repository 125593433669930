import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import "./App.css";

import { Home } from "../Home/Home";
import About from "../About/About";
import Contact from "../Contact/Contact";
import SocialMedia from "../SMM/Smm";
import Seo from "../SEO/Seo";
import SocialManagement from "../SocialManagement/SocialManagement";
import PersonalBranding from "../PersonalBranding/PersonalBranding";
import WebDevelopement from "../WebDevelopement/WebDevelopement";
import CopyWriting from "../CopyWriting/CopyWriting";
import ScrollTop from "../ScrollTop";
import Animation3D from "../3DAnimation/animation3D";
import CompanyLogo from "../image/CompanyLogo.jpeg";
// import ThankPage from "../thankPage/Thank";
import ComLogo from "../image/ComLogo.png";

function App() {
  function closeNavbar() {
    const navbarToggler = document.querySelector(".navbar-toggler");
    if (navbarToggler.getAttribute("aria-expanded") === "true") {
      const navbarCollapse = document.querySelector(".navbar-collapse");
      navbarToggler.classList.add("collapsed");
      navbarToggler.setAttribute("aria-expanded", "false");
      navbarCollapse.classList.remove("show");
    }
  }

  return (
    <Router>
      <nav class="navbar navbar-expand-lg text sticky-top abc ">
        <div class="container">
          <img
            src={ComLogo}
            alt="Logo"
            class="rounded-pill navbar-brand img-fluid"
            style={{ width: "110px", height: "90px" }}
          />
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarToggler"
            aria-expanded="false"
            aria-label="Toggle navigation"
            s
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div
            class="collapse navbar-collapse li1 justify-content-center"
            id="navbarNav"
          >
            <ul class="navbar-nav">
              <li class="nav-item ps-5 ">
                <Link
                  onClick={() => closeNavbar()}
                  className="nav-link  link_font"
                  to="/"
                >
                  {" "}
                  Home
                </Link>
              </li>
              <li class="nav-item ps-5 ">
                <Link
                  onClick={() => closeNavbar()}
                  className="nav-link  link_font"
                  to="/about"
                >
                  About
                </Link>
              </li>
              <li class="nav-item ps-5 dropdown">
                <a
                  class=" nav-link dropdown-toggle link_font"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                >
                  Services
                </a>
                <ul class="dropdown-menu">
                  <li className="mainmenu">
                    <div>
                      <Link
                        onClick={() => closeNavbar()}
                        to="/socialmediamarketing"
                        class="dropdown-item  service-textstyle "
                      >
                        {" "}
                        Social Media Marketing{" "}
                      </Link>
                    </div>
                    {/* <ul class="submenu dropdown-menu">
                      <li>
                        <Link
                          // onClick={() => closeNavbar()}
                          class="dropdown-item service-textstyle"
                          to="/upvcpartition"
                        >
                          UPVC Partition
                        </Link>
                      </li>
                    </ul> */}
                  </li>
                  <li className="mainmenu_1">
                    <div>
                      <Link
                        to="/seo"
                        class="dropdown-item service-textstyle "
                        onClick={() => closeNavbar()}
                      >
                        SEO{" "}
                      </Link>
                    </div>
                    {/* <ul class="submenu_1 dropdown-menu">
                      <li>
                        <Link
                          onClick={() => closeNavbar()}
                          class="dropdown-item service-textstyle"
                          to="/slidingdoor"
                        >
                          Sliding Doors
                        </Link>
                      </li>
                    </ul> */}
                  </li>
                  <li className="mainmenu">
                    <Link
                      onClick={() => closeNavbar()}
                      to="/SocialMediaManagement"
                      class="dropdown-item service-textstyle"
                    >
                      Social Media Management
                    </Link>
                  </li>
                  <li className="mainmenu">
                    <Link
                      onClick={() => closeNavbar()}
                      to="/PersonalBranding"
                      class="dropdown-item service-textstyle"
                    >
                      Personal Branding
                    </Link>
                  </li>
                  <li className="mainmenu">
                    <Link
                      onClick={() => closeNavbar()}
                      to="/WebDevelopement"
                      class="dropdown-item service-textstyle"
                    >
                      Web Development
                    </Link>
                  </li>
                  <li className="mainmenu">
                    <Link
                      onClick={() => closeNavbar()}
                      to="/CopyWriting"
                      class="dropdown-item service-textstyle"
                    >
                      Copy Writing
                    </Link>
                  </li>
                  {/* <li className="mainmenu">
                    <Link
                      onClick={() => closeNavbar()}
                      to="/animation"
                      class="dropdown-item service-textstyle"
                    >
                      animation
                    </Link>
                  </li> */}
                </ul>
              </li>
              {/* <li class="nav-item ps-5 ">
                <Link
                  onClick={() => closeNavbar()}
                  className="nav-link  link_font"
                  to="/update"
                >
                  Updates
                </Link>
              </li> */}

              <li class="nav-item ps-5  justify-content-end">
                <Link
                  onClick={() => closeNavbar()}
                  className="nav-link  link_font "
                  to="/contactUs"
                >
                  Contact us
                </Link>
              </li>
              <li class="nav-item ps-5  justify-content-end">
                <div className="quote-div">
                  <Link
                    onClick={() => closeNavbar()}
                    className="nav-link  link_font text-white  "
                    to="/contactUs"
                  >
                    Get a quote <i class="fa-solid fa-arrow-right"></i>
                  </Link>
                </div>
              </li>
              <div className="ps-4 Socialmedia-links mt-4">
                {/* <h5 className="text-center">DIGITAL MARKET</h5> */}
                <h6 className="">Contact Info</h6>
                <span className="address-text ">
                  {" "}
                  <i class="fa-solid fa-location-dot"></i> Sankarapuram, 3rd
                  Main Road, Choolaimedu, Chennai - 600094
                </span>{" "}
                <br />
                <span className="address-text ">
                  {" "}
                  <i class="fa-solid fa-envelope"></i> businessmsdm@gmail.com
                </span>
                <br />
                <span className="address-text ">
                  {" "}
                  <i class="fa-solid fa-phone"></i> +91 93632 88733
                </span>
                <hr />
                <div className="section d-flex gap-3 p-2">
                  <div className="link">
                    <a href="https://www.facebook.com/profile.php?id=61558578742946">
                      <i class="fa-brands fa-facebook-f"></i>
                    </a>
                  </div>
                  <div className="insta-link">
                    <a href="https://www.instagram.com/msdigitalmediamarketing">
                      <i class="fa-brands fa-instagram"></i>
                    </a>
                  </div>
                  <div className="link">
                    <a href="https://www.linkedin.com/in/malathy-m-suresh-27b00751">
                      <i class="fa-brands fa-linkedin-in"></i>
                    </a>
                  </div>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </nav>
      <ScrollTop />
      <Routes>
        <Route exact path="/" element={<Home />}></Route>
        <Route path="/about" element={<About />} />
        <Route path="/contactUs" element={<Contact />} />
        <Route path="/socialmediamarketing" element={<SocialMedia />} />
        <Route path="/seo" element={<Seo />} />
        <Route path="/SocialMediaManagement" element={<SocialManagement />} />
        <Route path="/PersonalBranding" element={<PersonalBranding />} />
        <Route path="/WebDevelopement" element={<WebDevelopement />} />
        <Route path="/CopyWriting" element={<CopyWriting />} />
        {/* <Route path="/animation" element={<Animation3D />} /> */}
        {/* <Route path="/thankyou" element={<ThankPage />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
