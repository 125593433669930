import React, { useEffect } from "react";
import "./Contact.css";
import logo from "../image/cotactwomen image.jpeg";
import logo1 from "../image/mobile-phone-1917737_1280.jpg";
import Typewriter from "typewriter-effect";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Footer from "../Footer/Footer";
import Speedbutton from "../SpeedButton/Speedbutton";
import CompanyLogo from "../image/CompanyLogo.jpeg";
import axios from "axios";
import { postLink } from "../URL/url";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  const Navigate = useNavigate();

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.type = "text/javascript";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  const handleClick = () => {
    if (window.Calendly) {
      window.Calendly.initPopupWidget({
        url: "https://calendly.com/businessmsdm/30min",
      });
    } else {
      console.error("Calendly is not defined");
    }
    return false;
  };

  return (
    <>
      <div className="container-fluid bg-colour">
        <div className="row">
          <div className="col-lg-12 contact-bg position-relative">
            <div className="contact_absolute text-center">
              <div id="app1">
                <Typewriter
                  options={{
                    loop: true,
                  }}
                  onInit={(typewriter) => {
                    typewriter
                      .typeString("Look Your Growth With Us")
                      .pauseFor(2500)
                      .deleteAll()

                      .start();
                  }}
                />
              </div>
            </div>
            <p className="text-center text-style">
              Share your business aspirations with us,
              <br /> and let's create something extraordinary together.
            </p>
          </div>
        </div>
        <div className="container contact-div">
          <div className="row mt-3 p-2">
            <div
              className="col-lg-7  mt-4"
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="100"
            >
              <div className="border p-3">
                <div className="d-flex gap-3 align-items-center">
                  <i class="fa-regular fa-comment"></i>{" "}
                  <span className="talk-text">Let's Talk</span>
                </div>

                <img src={logo} alt="image" className="team-logo" />
              </div>
            </div>
            <div
              className="col-lg-5  mt-4"
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="100"
            >
              {/* <div className="">
                <Formik
                  initialValues={InitialValues}
                  validationSchema={validationSchema}
                  onSubmit={(values, { resetForm }) => {
                  
                    handleSubmit(values, resetForm);
                    console.log("values", values);
                  }}
                >
                  {({ isSubmitting, handleChange, errors, values }) => (
                    <>
                      <Form className="form-container">
                        

                        <h4 className="text-center contact-text">Contact Us</h4>
                        <div className="form-group">
                          <label htmlFor="name" className="label">
                            Name:
                          </label>
                          <input
                            type="text"
                            name="name"
                            value={values.name}
                            className="input"
                            placeholder="Enter Name"
                            onChange={handleChange}
                          />
                          <ErrorMessage
                            name="name"
                            component="div"
                            className="error"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="email" className="label">
                            Email:
                          </label>
                          <input
                            type="email"
                            name="email"
                            className="input"
                            value={values.email}
                            placeholder="Enter a Email"
                            onChange={handleChange}
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="error"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="number" className="label">
                            Phone Number:
                          </label>
                          <input
                            type="number"
                            name="number"
                            className="input"
                            value={values.number}
                            placeholder="Enter a number"
                            onChange={handleChange}
                          />
                          <ErrorMessage
                            name="number"
                            component="div"
                            className="error"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="industry" className="label">
                            Industry:
                          </label>
                          <input
                            type="text"
                            name="industry"
                            className="input"
                            value={values.industry}
                            placeholder="Enter a industry"
                            onChange={handleChange}
                          />
                          <ErrorMessage
                            name="industry"
                            component="div"
                            className="error"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="comment" className="label">
                            Comment:
                          </label>
                          <Field
                            as="textarea"
                            name="comment"
                            onChange={handleChange}
                            className="textarea input"
                            value={values.comment}
                          />
                        </div>
                        <div className="submit-btn">
                          <button type="submit" className="button ">
                            Submit{" "}
                            <span>
                              <i class="fa-solid fa-arrow-right"></i>
                            </span>
                          </button>
                        </div>
                      </Form>
                      <ToastContainer />
                    </>
                  )}
                </Formik>
              </div> */}
              <div className=" text-center p-2 ">
                <h2 className=" calenly-text mt-5">
                  "We value your time. Book a call for a free consultation to
                  find out how we can help your business grow."
                </h2>
                <button
                  onClick={handleClick}
                  className="contactbook-button mt-5 "
                >
                  Book a Call <i className="fa-solid fa-arrow-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="container-fluid mt-3">
        <div className="row"></div>
      </div> */}
      <div className="link-div container-fluid p-3">
        <div className="container">
          <div className="row mt-4">
            <div
              className="col-lg-4 mt-5 text-center"
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="100"
            >
              <h5 className="text-white  text-center">
                MS DIGITAL MARKETING AGENCY
              </h5>
              <img
                src={CompanyLogo}
                className="img-fluid logostyle text-center mt-3"
                alt="logo"
              />
            </div>
            <div className="col-lg-4 mt-5 d-flex flex-column align-items-center">
              <h5 className="text-white text-center">Important Links</h5>
              <div className="">
                <div className="d-flex gap-3 align-items-center justify-content-center">
                  <i className="fa-solid fa-angles-right"></i>
                  <Link className="link-styles">Home</Link>
                </div>
                <div className="d-flex gap-3 align-items-center justify-content-center">
                  <i className="fa-solid fa-angles-right"></i>
                  <Link className="link-styles">About</Link>
                </div>
                <div className="d-flex gap-3 align-items-center justify-content-center">
                  <i className="fa-solid fa-angles-right"></i>
                  <Link className="link-styles">Service</Link>
                </div>
              </div>
            </div>

            <div className="col-lg-4 icons mt-5 text-center">
              <h5 className="text-white">Quick-Call</h5>
              <i class="fa-solid fa-phone mt-3"></i>
              <h6 className="text-white mt-3">+91 93632 88733</h6>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row mt-4">
          <div
            className="col-lg-12"
            data-aos="fade-up"
            data-aos-duration="800"
            data-aos-delay="100"
          >
            <div className="">
              <iframe
                className="map-div mt-4"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.5537526260155!2d80.22516977373355!3d13.064052412847333!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5266897c670d69%3A0x7a8b1ef6a7fbf750!2sSankarapuram%203rd%20St%2C%20Thiruvalluvar%20Puram%2C%20Choolaimedu%2C%20Chennai%2C%20Tamil%20Nadu%20600094!5e0!3m2!1sen!2sin!4v1723566129166!5m2!1sen!2sin"
                width="100%"
                height="450"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Speedbutton />
    </>
  );
};

export default Contact;
