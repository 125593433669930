import React from "react";
import "./PersonalBranding";
import { Link } from "react-router-dom";
import Typewriter from "typewriter-effect";
import Footer from "../Footer/Footer";
import brand from "../image/personalbrand---.jpeg";
const PersonalBranding = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row SMM-div">
          <div className="col-lg-3">
            {/* <div className="mt-5 return-link">
              <Link to="/" className="servicelinks ">
                Home
              </Link>
              <span>/Personal Branding</span>
            </div> */}
          </div>
          <div className="col-lg-6  smm-banner">
            <div id="app" className="mt-5">
              <Typewriter
                options={{
                  loop: true,
                }}
                onInit={(typewriter) => {
                  typewriter
                    .typeString("PERSONAL BRANDING")
                    .pauseFor(2500)
                    .deleteAll()

                    .start();
                }}
              />
            </div>
          </div>
          <div className="col-lg-3"></div>
        </div>
      </div>
      <div className="container-fluid service-container">
        <div className="container ">
          <div className="row">
            <div className="col-lg-6">
              <img
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
                className="static-image mt-5 zoom-on-hover rounded-circle brand-image"
                src={brand}
                alt="image"
                height={400}
                width={400}
              />
            </div>
            <div className="col-lg-6 ">
              <h5
                className="seo-head mt-5"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                BUILD YOUR OWN BRAND
              </h5>
              <h2
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                PERSONAL BRANDING
              </h2>
              <p
                className="web-text"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                Personal branding is the process of creating a unique image and
                identity for yourself in the professional world. It involves
                showcasing your skills, experiences, values, and personality to
                differentiate yourself from others. A strong personal brand
                helps you build credibility, attract opportunities, and connect
                with your target audience. By consistently communicating your
                unique qualities and expertise, you can establish a memorable
                and influential presence in your industry.
              </p>
              <p
                className="web-text"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                Personal branding is the strategic practice of defining and
                promoting what makes you unique and valuable in your field. It
                involves crafting a consistent narrative around your skills,
                experiences, and personal values that resonate with your target
                audience
              </p>
            </div>
          </div>
          <div className="row mt-3">
            <h2
              className="seo-head1 text-center"
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="100"
            >
              Make your Own Personal Brand With Ms Digital Media!
            </h2>
            <div
              className="col-lg-12 d-flex justify-content-center gap-5  product-list "
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="100"
            >
              <div className="mt-3">
                <div className="d-flex gap-2 align-items-center ">
                  <i class="fa-regular fa-circle-check"></i>{" "}
                  <h5 className="mt-1">Engagement</h5>
                </div>
                <div className="d-flex gap-2 align-items-center mt-3 ">
                  <i class="fa-regular fa-circle-check"></i>{" "}
                  <h5 className="mt-1">Credibility</h5>
                </div>
                <div className="d-flex gap-2 align-items-center mt-3 ">
                  <i class="fa-regular fa-circle-check"></i>{" "}
                  <h5 className="mt-1">Value Proposition</h5>
                </div>
              </div>

              <div className="mt-3">
                <div className="d-flex gap-2 align-items-center ">
                  <i class="fa-regular fa-circle-check"></i>{" "}
                  <h5 className="mt-1">Consistency</h5>
                </div>
                <div className="d-flex gap-2 align-items-center  mt-3 ">
                  <i class="fa-regular fa-circle-check"></i>{" "}
                  <h5 className="mt-1">Authenticity</h5>
                </div>
                <div className="d-flex gap-2 align-items-center mt-3 ">
                  <i class="fa-regular fa-circle-check"></i>{" "}
                  <h5 className="mt-1">Visibility</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <h3
              className="text-center web-head"
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="100"
            >
              WHY PERSONEL BRANDING IS IMPORTANT !
            </h3>
            <div className="col-lg-12">
              <p
                className="web-text mt-3"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                personal branding is about representing yourself and helping
                others know who you are?, what you do?, why and how you do?
              </p>

              <p
                className="web-text"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                In your daily interactions with others, they unconsciously
                search for any and all information to help them decide whether
                it’s safe and beneficial to continue to do any business with
                you. With a personal brand, it becomes easier for others to
                identify you as their best choice.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PersonalBranding;
