import React from "react";
import "./About.css";
import WomenImage from "../image/women-8577389_1280.jpg";
import Typewriter from "typewriter-effect";
import Footer from "../Footer/Footer";
import Collapse from "../Collapse/Collapse";
import Collapsesection from "../Collapse/Collapse";
import Speedbutton from "../SpeedButton/Speedbutton";

const About = () => {
  return (
    <>
      {/* accordian */}
      <div className="container-fluid">
        <div className="row text-animation">
          <div className="col-lg-12 " id="app">
            <div className="mt-4">
              <Typewriter
                options={{
                  loop: true,
                }}
                onInit={(typewriter) => {
                  typewriter
                    .typeString("MS Digital Media")
                    .pauseFor(2500)
                    .deleteAll()
                    .typeString("Digital Marketing Experts")
                    .pauseFor(2500)
                    .deleteAll()
                    .typeString(
                      "<strong>Transforming Your Online Presence</strong>"
                    )
                    .pauseFor(2500)
                    .start();
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="conatiner-fluid">
        <div className="container">
          <div className="row mt-5">
            <div
              className="col-lg-12 aboutus  text-center"
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="100"
            >
              <i class="fa-solid fa-star"></i>{" "}
              <span className="span-text">
                BEST DIGITAL MARKETING AGENCY IN CHENNAI
              </span>
              <h2 className="mt-3">About Us</h2>
              <p className="mt-5">
                <span className="span-para">Welcome to MS Digital Media!</span>{" "}
                We help businesses grow online with effective digital marketing
                strategies. Our services include SEO, content marketing, social
                media management, and PPC campaigns. Our expert team is
                dedicated to boosting your online presence and driving success.
                Let us help you reach new heights in the digital world!
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container-fluid p-3"
        //   style={{ backgroundColor: "rgb(242,244,248)" }
        // }
      >
        <div className="container">
          <div className="row mt-4">
            <h1
              className="text-center funtametal-text"
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="100"
            >
              Our Fundamental Values!
            </h1>
            <div className="col-lg-4 mt-5">
              <div
                className="vision-border p-4"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                <i class="fa-regular fa-eye"></i>
                <h2 className="text-center mt-4">Our Vision</h2>
                <p className="vision-text mt-4">
                  "We drive transformative growth and innovation, helping
                  businesses of all sizes achieve their full potential in the
                  digital landscape."
                </p>
              </div>
            </div>
            <div className="col-lg-4 mt-5">
              <div
                className="mission-border p-4"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                <i class="fa-solid fa-bullseye"></i>
                <h2 className="text-center mt-4">Our Mission</h2>
                <p className="vision-text mt-4">
                  "Our mission is to boost business growth through innovative
                  and ethical solutions. We prioritize our clients by meeting
                  their needs with excellence and delivering consistent,
                  high-quality results."
                </p>
              </div>
            </div>
            <div className="col-lg-4 mt-5">
              <div
                className="values-border p-4"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                <i class="fa-regular fa-handshake"></i>
                <h2 className="text-center mt-4">Our Values</h2>
                <p className="vision-text mt-4">
                  "We values—innovation, integrity, customer focus, and
                  excellence—shape our approach. These principles guide us in
                  upholding the highest ethical standards while ensuring that
                  our clients remain at the heart of everything we do."
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container-fluid p-3"
        style={{ backgroundColor: "#221b40" }}
      >
        <div className="row mt-4">
          <div
            className="col-lg-6"
            data-aos="fade-up"
            data-aos-duration="800"
            data-aos-delay="100"
          >
            <div className="choose-div">
              <h5 className=" choose-text">WHY CHOOSE US</h5>
            </div>

            <h1 className="text-white mt-3">
              We Provide Best Service For Digital Marketing
            </h1>
            <p className="text-white vision-text">
              Just as we've shaped remarkable outcomes for our clients, we
              commit to delivering unparalleled digital excellence. This isn't
              merely a service; it's a guarantee of your success
            </p>
            <div className=" row about-section  gap-5 p-5">
              <div className="col-lg-6">
                {" "}
                <i class="fa-solid fa-circle-check"></i>
                <h4 className="text-white mt-2">Save Your Time</h4>
                <p className="text-white vision-text">
                  We value your time as much as you do. Our efficient processes
                  guarantee swift and effective results, delivering what you
                  need without delay.
                </p>
              </div>
              <div className="col-lg-6">
                {" "}
                <i class="fa-solid fa-circle-check"></i>
                <h4 className="text-white mt-2">Best Strategy</h4>
                <p className="text-white vision-text">
                  Our strategy combines data-driven insights, innovative
                  thinking, and the latest marketing trends, all focused on
                  achieving the best results for our clients.
                </p>
              </div>
            </div>
            <div className=" row about-section support-div  d-flex gap-5 p-5">
              <div className="col-lg-6">
                {" "}
                <i class="fa-solid fa-circle-check"></i>
                <h4 className="text-white mt-2">Affordable Price</h4>
                <p className="text-white vision-text">
                  We offer reasonable pricing without compromising on results,
                  ensuring our top-notch digital marketing services provide the
                  best value for your investment.
                </p>
              </div>
              <div className="col-lg-6">
                {" "}
                <i class="fa-solid fa-circle-check"></i>
                <h4 className="text-white mt-2"> Best Customers Support</h4>
                <p className="text-white vision-text">
                  {" "}
                  Your success is our top priority. We’re here to support you
                  every step of the way, ensuring your needs are met promptly
                  and effectively.
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-lg-6"
            data-aos="fade-up"
            data-aos-duration="800"
            data-aos-delay="100"
          >
            <img className="  about-image" src={WomenImage} alt="imag" />
          </div>
        </div>
      </div>
      <div className="container-fluid mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2
                className="text-center"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                Frequently Asked Questions
              </h2>
            </div>
          </div>
        </div>
      </div>

      <Collapsesection />
      <Speedbutton />
      <Footer />
    </>
  );
};

export default About;
