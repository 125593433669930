import React from "react";
import { FloatButton } from "antd";
// import { FaCustomerService, FaComment } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { IoShareSocial } from "react-icons/io5";
import { FaWhatsapp } from "react-icons/fa";
import "./abc.css";
import { Link } from "react-router-dom";
const Speedbutton = () => {
  return (
    // <FloatButton.Group
    //   trigger="click"
    //   type="primary"
    //   style={{ right: 24 }}
    //   icon={<IoShareSocial className="social-icons" />}
    // >
    //   <FloatButton
    //     className="fa-insta"
    //     icon={<FaWhatsapp className="whats-app" />}
    //   />
    //   <FloatButton
    //     className="fa-insta"
    //     style={{ color: "orange" }}
    //     icon={<FaInstagram className="icons-style" />}
    //   />
    // </FloatButton.Group>
    <Link to="https://wa.me/message/WYW6B2GTORZZB1" className="whatsapp-icon">
      <i class="fa-brands fa-whatsapp"></i>
    </Link>
  );
};

export default Speedbutton;
