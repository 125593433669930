import React from "react";
import "./SocialManagement.css";
import { Link } from "react-router-dom";
import Typewriter from "typewriter-effect";
import socialmanagement from "../image/social management.jpg";
import Footer from "../Footer/Footer";
import Speedbutton from "../SpeedButton/Speedbutton";
const SocialManagement = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row SMM-div">
          <div className="col-lg-3">
            {/* <div className="mt-5 return-link">
              <Link to="/" className="servicelinks ">
                Home
              </Link>
              <span>/Social Media Management</span>
            </div> */}
          </div>
          <div className="col-lg-6  smm-banner">
            <div id="app" className="mt-5">
              <Typewriter
                options={{
                  loop: true,
                }}
                onInit={(typewriter) => {
                  typewriter
                    .typeString(" SOCIAL MEDIA MANAGEMENT")
                    .pauseFor(2500)
                    .deleteAll()

                    .start();
                }}
              />
            </div>
          </div>
          <div className="col-lg-3"></div>
        </div>
      </div>
      <div className="conatiner-fluid service-container">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 text-center">
              <img
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
                className=" rounded-circle  static-image mt-5 zoom-on-hover "
                src={socialmanagement}
                alt="logo"
                height={400}
                width={400}
              />
            </div>
            <div className="col-lg-6 mt-3 p-3">
              <h6
                className="seo-head mt-5"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                START YOUR SOCIAL MEADIA MANAGEMENT{" "}
              </h6>
              <h2
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                SOCIAL MEADIA MANAGEMENT
              </h2>
              <p
                className="web-text"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                Social media management is the process of creating and
                scheduling content to grow and nurture an audience across social
                media platforms. It's about strategically creating content,
                fostering connections and building a loyal audience.
              </p>
              <p
                className="web-text"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                Effective social media management involves more than just
                posting quality content; it's about fostering a community. It
                helps businesses develop relationships with their target
                audience, boosting brand awareness and loyalty.
              </p>
            </div>
          </div>
          <div className="row mt-5 p-2">
            <h2
              className="seo-head1 text-center"
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="100"
            >
              Why Social Media Management Is Important!
            </h2>
            <div
              className="col-lg-12 d-flex justify-content-center gap-5  product-list"
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="100"
            >
              <div className="mt-3">
                <div className="d-flex gap-2 align-items-center ">
                  <i class="fa-regular fa-circle-check"></i>{" "}
                  <h5 className="mt-1">Brand Visibility</h5>
                </div>
                <div className="d-flex gap-2 align-items-center mt-3 ">
                  <i class="fa-regular fa-circle-check"></i>{" "}
                  <h5 className="mt-1">Audience Engagement</h5>
                </div>
                <div className="d-flex gap-2 align-items-center mt-3 ">
                  <i class="fa-regular fa-circle-check"></i>{" "}
                  <h5 className="mt-1">Reputation Management</h5>
                </div>
                <div className="d-flex gap-2 align-items-center mt-3 ">
                  <i class="fa-regular fa-circle-check"></i>{" "}
                  <h5 className="mt-1">Drive Traffic and Sales</h5>
                </div>
              </div>

              <div className="mt-3">
                <div className="d-flex gap-2 align-items-center ">
                  <i class="fa-regular fa-circle-check"></i>{" "}
                  <h5 className="mt-1">Targeted Marketing</h5>
                </div>
                <div className="d-flex gap-2 align-items-center  mt-3 ">
                  <i class="fa-regular fa-circle-check"></i>{" "}
                  <h5 className="mt-1">Analytics and Insights</h5>
                </div>
                <div className="d-flex gap-2 align-items-center mt-3 ">
                  <i class="fa-regular fa-circle-check"></i>{" "}
                  <h5 className="mt-1">Competitive Advantage</h5>
                </div>
                <div className="d-flex gap-2 align-items-center mt-3 ">
                  <i class="fa-regular fa-circle-check"></i>{" "}
                  <h5 className="mt-1">Cost-Effective Marketing</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Speedbutton />
    </>
  );
};

export default SocialManagement;
