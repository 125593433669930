import React from "react";
import { Collapse, Space } from "antd";
import "./Collapse.css";
const { Panel } = Collapse;

const Collapsesection = () => {
  return (
    <>
      <div className="container mt-4">
        <div className="row">
          <div className="col-lg-12">
            <Collapse collapsible="header">
              <Panel key="1" header={<h5>What is Digital Marketing?</h5>}>
                <p className="collapse-text">
                  Digital marketing is promoting your products or services using
                  the internet. This includes things like social media, email,
                  search engines, and websites to reach and engage customers.
                </p>
              </Panel>
            </Collapse>
            <Collapse className="mt-2" collapsible="icon">
              <Panel
                key="2"
                header={
                  <h5>Why is Digital Marketing important for my business?</h5>
                }
              >
                <p className="collapse-text">
                  Digital marketing is important because it helps you reach more
                  people, is cost-effective, allows you to measure results,
                  improves customer engagement, increases sales, and keeps you
                  competitive.
                </p>
              </Panel>
            </Collapse>
            <Collapse className="mt-2" collapsible="icon">
              <Panel
                key="3"
                header={
                  <h5>
                    How long does it take to see results from Digital Marketing?
                  </h5>
                }
              >
                <p className="collapse-text">
                  Results can vary, but typically you might see some progress in
                  a few months. SEO can take longer, while paid ads and social
                  media can show quicker results.
                </p>
              </Panel>
            </Collapse>
            <Collapse className="mt-2" collapsible="icon">
              <Panel
                key="4"
                header={<h5>How do I get started with Digital Marketing</h5>}
              >
                <p className="collapse-text">
                  Set clear goals. Identify your target audience , Create a
                  strategy , Build a strong online presence , Produce engaging
                  content , Launch campaigns , Monitor performance ,
                  Continuously improve ,
                </p>
              </Panel>
            </Collapse>
            <Collapse className="mt-2" collapsible="icon">
              <Panel
                key="5"
                header={
                  <h5>
                    How do you determine which Digital Marketing strategies are
                    best for my business?
                  </h5>
                }
              >
                <p className="collapse-text">
                  Analyzing your business goals and audience , Reviewing your
                  current performance , Identifying effective channels ,
                  Developing a tailored strategy , Planning and executing
                  campaigns , Monitoring results and making adjustments ,
                </p>
              </Panel>
            </Collapse>
          </div>
        </div>
      </div>
    </>
  );
};

export default Collapsesection;
