import React from "react";
import { Link } from "react-router-dom";
import "./Footre.css";
// import logo from "../image/logo.jpeg";
import ComLogo from "../image/CompanyLogo.jpeg";
const Footer = () => {
  return (
    <div className="container-fluid footer-container">
      <div className="row mt-4">
        <div className="col-lg-4 mt-5 ">
          <div className="text-center">
            <h4 className="text-white">Digital Marketing Sevices</h4>
            <div className="d-flex mt-2 align-items-center gap-3 text-center ">
              <i class="fa-solid fa-angles-right"></i>{" "}
              <sapn>
                <Link className="footer-link" to="/socialmediamarketing">
                  Social Media Marketing
                </Link>
              </sapn>
            </div>
            <div className="d-flex mt-2 align-items-center gap-3 ">
              <i class="fa-solid fa-angles-right"></i>{" "}
              <sapn>
                <Link className="footer-link" to="/seo">
                  SEO
                </Link>
              </sapn>
            </div>
            <div className="d-flex mt-2 align-items-center gap-3 ">
              <i class="fa-solid fa-angles-right"></i>{" "}
              <sapn>
                <Link className="footer-link" to="/SocialMediaManagement">
                  Social Media Management
                </Link>
              </sapn>
            </div>
            <div className="d-flex mt-2 align-items-center gap-3 ">
              <i class="fa-solid fa-angles-right"></i>{" "}
              <sapn>
                <Link className="footer-link" to="/PersonalBranding">
                  Personal Branding
                </Link>
              </sapn>
            </div>
            <div className="d-flex mt-2 align-items-center gap-3 ">
              <i class="fa-solid fa-angles-right"></i>{" "}
              <sapn>
                <Link className="footer-link" to="WebDevelopement">
                  Web Developement
                </Link>
              </sapn>
            </div>
            <div className="d-flex mt-2 align-items-center gap-3 ">
              <i class="fa-solid fa-angles-right"></i>{" "}
              <sapn>
                <Link className="footer-link" to="/CopyWriting">
                  {" "}
                  Copy Writing
                </Link>
              </sapn>
            </div>
          </div>
        </div>
        <div className="col-lg-4 text-center mt-5">
          <>
            <div className="img-div">
              <img className="img-fluid footer-img" src={ComLogo} alt="image" />
            </div>
          </>
        </div>
        <div className="col-lg-4 mt-5">
          <>
            <h4 className="text-white">Navigation</h4>
            <div className="d-flex mt-2 align-items-center gap-3 ">
              <i class="fa-solid fa-angles-right"></i>{" "}
              <sapn>
                <Link className="footer-link" to="/">
                  Home
                </Link>
              </sapn>
            </div>
            <div className="d-flex mt-2 align-items-center gap-3 ">
              <i class="fa-solid fa-angles-right"></i>{" "}
              <sapn>
                <Link className="footer-link" to="about">
                  About
                </Link>
              </sapn>
            </div>
            <div className="d-flex mt-2 align-items-center gap-3 ">
              <i class="fa-solid fa-angles-right"></i>{" "}
              <sapn>
                <Link className="footer-link" to="/contactUs">
                  Contact Us
                </Link>
              </sapn>
            </div>
          </>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 mt-2">
          <div className="d-flex justify-content-center gap-5 socialmeadia-links">
            <Link to="https://wa.me/message/WYW6B2GTORZZB1">
              {" "}
              <i class="fa-brands fa-whatsapp"></i>
            </Link>
            <Link to="https://www.instagram.com/msdigitalmediamarketing">
              <i class="fa-brands fa-instagram"></i>
            </Link>
            <Link to="https://www.linkedin.com/in/malathy-m-suresh-27b00751">
              <i class="fa-brands fa-linkedin-in"></i>
            </Link>
            <Link to="https://www.facebook.com/profile.php?id=61558578742946">
              <i class="fa-brands fa-facebook"></i>
            </Link>
          </div>
        </div>
      </div>
      <div className="row mt-5 details-div">
        <hr className="divider" />
        <div className="col-lg-4 d-flex gap-3 mt-3 ">
          <i class="fa-solid fa-phone-volume"></i>
          <p className="text-white">+91 93632 88733</p>
        </div>
        <div className="col-lg-4 d-flex gap-3 mt-3">
          <i class="fa-regular fa-envelope"></i>
          <h6 className="text-white"> businessmsdm@gmail.com</h6>
        </div>
        <div className="col-lg-4 d-flex gap-3 mt-3">
          <i class="fa-solid fa-location-dot"></i>
          <h6 className="text-white">
            Sankarapuram, 3rd Main Road, Choolaimedu, Chennai - 600094
          </h6>
        </div>
      </div>
    </div>
  );
};

export default Footer;
