import React from "react";
import Typewriter from "typewriter-effect";
import "./Smm.css";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";
import SMM from "../image/smm.jpg";
import Speedbutton from "../SpeedButton/Speedbutton";
const SocialMedia = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row SMM-div">
          <div className="col-lg-3">
            {/* <div className="mt-5 return-link">
              <Link to="/" className="servicelinks ">
                Home
              </Link>
              <span>/Social Media Marketing</span>
            </div> */}
          </div>
          <div className="col-lg-6  smm-banner">
            <div id="app" className="mt-5">
              <Typewriter
                options={{
                  loop: true,
                }}
                onInit={(typewriter) => {
                  typewriter
                    .typeString(" SOCIAL MEDIA MARKETING")
                    .pauseFor(2500)
                    .deleteAll()

                    .start();
                }}
              />
            </div>
          </div>
          <div className="col-lg-3"></div>
        </div>
      </div>
      <div className="container-fluid service-container">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <img
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
                className=" rounded-circle  static-image mt-5 zoom-on-hover "
                src={SMM}
                alt="logo"
                height={400}
                width={400}
              />
            </div>
            <div className="col-lg-6">
              <h5
                className="seo-head mt-5"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                LET'S START YOUR BUSNESS
              </h5>
              <h2
                className="mt-3"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                SOCIAL MEDIA MARKETING
              </h2>
              <p
                className="web-text"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                We take your brand to a whole new level with effective social
                media like facebook,instagramand linkedin.we have clear plan of
                action and a detailed competitor analysis, your barnd will de
                set to make its presence on all social platforms. It enables
                companies to engage with current customers and attract new ones.
                Social media marketing also includes data analytics tools that
                help marketers measure the success of their campaigns and
                discover new opportunities for engagement
              </p>
              <p
                className="web-text"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                First we have analyis your problems and we find solution and
                then proceed.social medial marketing is easy way to reach
                customers because Now days all are using social media so easily
                reach out the customers. Now days social media bridge between
                seller and customers.
              </p>
            </div>
          </div>
          <div className="row mt-3">
            <h2
              className="text-center seo-head1"
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="100"
            >
              Why Is Social Media Marketing (SMM) So Powerful?
            </h2>
            <div className="col-lg-12">
              <p
                className="text-center mt-3 smm-text"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                The power of SMM is driven by the unparalleled capacity of
                social media in three core marketing areas: connection,
                interaction, and customer data.
              </p>
              <p
                className="text-center mt-3 web-text"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                <span className="copywriting-text">Connection: </span> Social
                media not only allows businesses to connect with customers in
                unprecedented ways, but it also offers a wide variety of
                channels to reach target audiences. These include content
                platforms like YouTube, social networking sites like Facebook,
                and microblogging services like X (formerly Twitter).
              </p>
              <p
                className="text-center mt-3 web-text"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                <span className="copywriting-text">Interaction: </span>The
                dynamic nature of social media interactions—ranging from direct
                communication to passive likes—allows businesses to harness free
                advertising opportunities through electronic word-of-mouth
                (eWOM) recommendations shared between current and prospective
                customers.
              </p>
              <p
                className="text-center mt-3 web-text"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                <span className="copywriting-text">Customer Data: </span>A
                well-designed social media marketing plan delivers another
                invaluable resource to boost marketing outcomes: customer data
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Speedbutton />
    </>
  );
};

export default SocialMedia;
