import React from "react";
import logo from "../image/testimonialImage.jpeg";
import logo1 from "../image/testiiImage.jpeg";
import "./Carousel.css";
import Image3 from "../image/saravanaimg.jpeg";
import image4 from "../image/naveenimag.jpeg";
const Carousel = () => {
  return (
    <div className="container">
      <div className="row mt-5">
        <h2 className="text-center testimonials-text">
          Real stories,real results See our clients' success testimonials!
        </h2>
        <div className="col-lg-12 mt-3 corosildisply-div">
          <div
            id="carouselExampleControls"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-inner">
              <div
                className="carousel-item active"
                style={{ height: "auto", backgroundColor: "#f2f4f8" }}
              >
                <div className="row">
                  <div className=" col-lg-12    p-5">
                    <div className=" d-flex align-items-center gap-5 corousil-div">
                      <img src={logo} alt="image" className="carosil-image" />
                      <p className="carosil-text">
                        <h5>Lakshmi Narayanan</h5>
                        MS Digital Media is an excellent agency to partner with.
                        They not only provide top-notch marketing services but
                        also focus on driving business growth. Their ad
                        creatives are exceptional, and their data-driven
                        approach is impressive. I highly recommend MS Digital
                        Media to anyone looking to expand their business.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="carousel-item"
                style={{ height: "auto", backgroundColor: "#f2f4f8" }}
              >
                <div className="row">
                  <div className=" col-lg-12    p-5">
                    <div className=" d-flex align-items-center gap-5 corousil-div">
                      <img
                        src={Image3}
                        alt="image"
                        className="carosil-image3"
                      />
                      <p className="carosil-text">
                        <h5>Saravanan M</h5>
                        We appreciate your efforts in helping us reach out to
                        potential clients. The strategies implemented have shown
                        positive results, and we have seen an increase in
                        inquiries about our website building services
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="carousel-item"
                style={{ height: "auto", backgroundColor: "#f2f4f8" }}
              >
                <div className="row">
                  <div className=" col-lg-12  p-5">
                    <div className=" d-flex align-items-center gap-5 corousil-div">
                      <img
                        src={image4}
                        className="carosil-image4"
                        alt="image"
                      />
                      <p className="carosil-text">
                        <h5>Naveenkumar N</h5>
                        MS Digital Media's service is exceptional. We highly
                        value the quality of the leads provided. The clients who
                        have reached out are better aligned with our target
                        audience, resulting in a noticeable improvement in our
                        conversion rates. Their expertise in delivering targeted
                        leads has significantly contributed to our success.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="carousel-item"
                style={{ height: "auto", backgroundColor: "#f2f4f8" }}
              >
                <div className="row">
                  <div className=" col-lg-12 p-5">
                    <div className="d-flex align-items-center corousil-div gap-5 ">
                      <img src={logo1} alt="image" className="carosil-image" />
                      <p className="carosil-text">
                        Thanks to MS Digital Media, their digital marketing
                        strategies has increased quality leads and sales. Their
                        team is reliable, skilled, and truly understands our
                        requirement.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <a
              className="carousel-control-prev"
              href="#carouselExampleControls"
              role="button"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#carouselExampleControls"
              role="button"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>
      </div>
      <div className="row mobiledisplay-div p-3 mt-5">
        <div className="col-lg-6 boxshadows  mt-5">
          <div className="d-flex justify-content-center gap-3 flex-column">
            <img src={logo} alt="image" className="mobile-image" />
            <p className="carosil-text">
              <h5>Lakshmi Narayanan</h5>
              MS Digital Media is an excellent agency to partner with. They not
              only provide top-notch marketing services but also focus on
              driving business growth. Their ad creatives are exceptional, and
              their data-driven approach is impressive. I highly recommend MS
              Digital Media to anyone looking to expand their business.
            </p>
          </div>
        </div>
        <div className="col-lg-6 boxshadows  mt-5">
          <div className="d-flex justify-content-center gap-3 flex-column">
            <img src={image4} alt="image" className="mobile-image" />
            <p className="carosil-text">
              <h5>Naveenkumar N</h5>
              MS Digital Media's service is exceptional. We highly value the
              quality of the leads provided. The clients who have reached out
              are better aligned with our target audience, resulting in a
              noticeable improvement in our conversion rates. Their expertise in
              delivering targeted leads has significantly contributed to our
              success.
            </p>
          </div>
        </div>
      </div>
      <div className="row mobiledisplay-div p-3 mt-5">
        <div className="col-lg-6 boxshadows p-2 mt-2">
          <div className="d-flex justify-content-center gap-3 flex-column ">
            <img src={Image3} alt="image" className="mobile-image" />
            <p className="carosil-text">
              <h5>Saravanan M</h5>
              We appreciate your efforts in helping us reach out to potential
              clients. The strategies implemented have shown positive results,
              and we have seen an increase in inquiries about our website
              building services
            </p>
          </div>
        </div>

        <div className="col-lg-6 boxshadows p-2 mt-2">
          <div className="d-flex justify-content-center gap-3 flex-column ">
            <img src={logo1} alt="image" className="mobile-image" />
            <p className="carosil-text">
              Thanks to MS Digital Media, their digital marketing strategies has
              increased quality leads and sales. Their team is reliable,
              skilled, and truly understands our requirement.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
