import React from "react";
import AOS from "aos";
import { useEffect } from "react";
import banner from "../image/womenbanner.png";
import "./Home.css";

import logo from "../image/bannerwomen23.jpeg";
import onlinepresence from "../image/images.png";
import digital from "../image/finaldgiImage.jpeg";
import Footer from "../Footer/Footer";
import laptop from "../image/laptop.jpg";
import discover from "../image/discover-2.jpeg";
import brush from "../image/brushes-3129361_1280.jpg";
import target from "../image/targetimag.jpg";
import Speedbutton from "../SpeedButton/Speedbutton";
import { Link } from "react-router-dom";
import Carousel from "../carousel/Carousel";
import Animation3D from "../3DAnimation/animation3D";
import Myvideon from "../video/video.mp4";

AOS.init();
export const Home = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.type = "text/javascript";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  const handleClick = () => {
    if (window.Calendly) {
      window.Calendly.initPopupWidget({
        url: "https://calendly.com/businessmsdm/30min",
      });
    } else {
      console.error("Calendly is not defined");
    }
    return false;
  };
  return (
    <>
      <div className="container-fluid">
        {/* <div className="row banner-div">
          <div className="col-lg-6 p-5 scale-animi text-div ">
            <h5 className="Hook-style">GO DIGITAL, GROW FASTER</h5>
            <h1 className="text-white">
              GET <span>MORE CUSTOMERS WITH </span>DIGITAL MARKETING
            </h1>
            <p className="text-white">
              Want more customers? Digital Media can help! We use SEO, ads, and
              social media to make sure people find your business online. Let’s
              grow your business together!
            </p>
            <button onClick={handleClick} className="book-button">
              Book a Call <i className="fa-solid fa-arrow-right"></i>
            </button>
          </div>
          <div className="col-lg-6 bannerimage-div  ">
            <div className="circle-div">
              <div className="face-icons">
                <i class="fa-brands fa-facebook-f"></i>
              </div>
              <div className="insta-iconsdiv">
                <i class="fa-brands fa-instagram"></i>
              </div>
              <div className="linked-icons">
                <i class="fa-brands fa-linkedin-in"></i>
              </div>
            </div>
            <img className="" src={banner} alt="image" />
          </div>
        </div> */}

        <div className="row ">
          <div className="col-lg-12 globe-div p-0">
            <div className="globetext-div1 text-center">
              <h1 className="text-white text-center globe-text">
                GET <span>MORE CUSTOMERS WITH </span> <br />
                DIGITAL MARKETING
              </h1>
              <p className=" globe-text1 text-center">
                Want more customers? Digital Media can help! We use SEO, ads,{" "}
                and social media to make sure people
                <br /> find your business online. Let’s grow your business
                together!
              </p>
              <button onClick={handleClick} className="book-button">
                Book a Call <i className="fa-solid fa-arrow-right"></i>
              </button>
            </div>

            <video
              className="video-player"
              controls={false}
              autoPlay
              loop
              muted
              controlsList="nofullscreen"
            >
              <source src={Myvideon} type="video/mp4" />
            </video>
          </div>
        </div>
        <div className="row banner-div1">
          <div className="col-lg-12"></div>
        </div>
        {/* 2nd row */}
        <div className="container">
          <div className="row mt-5">
            <div className="col-lg-6 mt-4 ">
              <div className="bg-image">
                <div
                  data-aos="fade-up"
                  data-aos-duration="800"
                  data-aos-delay="100"
                >
                  <img
                    className="img-fluid animate-image "
                    src={logo}
                    alt="image "
                    height={10}
                  />
                </div>
                <div
                  className="mt-5 round-div"
                  data-aos="fade-up"
                  data-aos-duration="800"
                  data-aos-delay="100"
                >
                  <img
                    className="img-fluid  digital-image"
                    src={digital}
                    alt="digital"
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6 profile-div mt-4">
              <div
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                <h5 className="Hook-style">ABOUT OUR COMPANY</h5>
                <h2>
                  Grow Your Business in the Digital World with MS Digital Media
                </h2>
                <p className="Hook-text">
                  At MS Digital Media, we solve your digital marketing problems.
                  From boosting online presence to driving sales, we provide
                  tailored solutions for real results. Partner with us for
                  hassle-free digital success.
                </p>
                <div
                  className="d-flex mt-2 gap-4"
                  data-aos="fade-up"
                  data-aos-duration="800"
                  data-aos-delay="100"
                >
                  {/* <img className="presence-image" src={onlinepresence} alt /> */}
                  <div class="graph-icons">
                    <i class="fa-solid fa-chart-line"></i>
                  </div>
                  <div>
                    <h6 className="inline about-text">
                      INCREASE YOUR ONLINE PRESENCE
                    </h6>
                    <span className="Hook-text ">
                      {" "}
                      At MS Digital Media, we help you establish a strong online
                      presence to ensure your brand stands out in the digital
                    </span>
                  </div>
                </div>{" "}
                <div
                  class="d-flex mt-4 gap-4 align-items-center"
                  data-aos="fade-up"
                  data-aos-duration="800"
                  data-aos-delay="100"
                >
                  <div class="graph-icons">
                    <i class="fa-solid fa-chart-simple"></i>
                  </div>
                  <div>
                    <h6 class="inline mb-0 about-text">
                      DRIVE MORE TRAFFIC TO YOUR WEBSITE
                    </h6>{" "}
                    <span className="Hook-text ">
                      We are designed to attract more visitors to your site,
                      increasing visibility and engagement.
                    </span>
                  </div>
                </div>
                <div
                  className="d-flex mt-4 gap-4 align-items-center"
                  data-aos="fade-up"
                  data-aos-duration="800"
                  data-aos-delay="100"
                >
                  <div class="graph-icons">
                    <i class="fa-solid fa-handshake"></i>
                  </div>
                  <div>
                    <h6 class="inline mb-0 about-text">BOOST YOUR SALES</h6>{" "}
                    <span className="Hook-text ">
                      We implement targeted campaigns to convert traffic into
                      customers, driving your sales growth.
                    </span>
                  </div>
                </div>
                <div
                  className="d-flex mt-4 gap-4 align-items-center"
                  data-aos="fade-up"
                  data-aos-duration="800"
                  data-aos-delay="100"
                >
                  <div class="graph-icons">
                    <i class="fa-solid fa-check-double"></i>
                  </div>
                  <div>
                    <h6 class="inline mb-0 about-text">
                      {" "}
                      TAILORED STRATEGIES{" "}
                    </h6>{" "}
                    <span className="Hook-text ">
                      Our solutions are customized to meet your unique needs,
                      ensuring your marketing efforts are effective and
                      efficient.
                    </span>
                  </div>
                </div>
                <div
                  className="d-flex mt-4 gap-4 align-items-center"
                  data-aos="fade-up"
                  data-aos-duration="800"
                  data-aos-delay="100"
                >
                  <div class="graph-icons">
                    <i class="fa-solid fa-thumbs-up"></i>
                  </div>
                  <div>
                    <h6 class="inline mb-0 about-text">
                      {" "}
                      HASSLE-FREE SOLUTION{" "}
                    </h6>{" "}
                    <span className="Hook-text ">
                      Partner with us for seamless digital marketing services
                      that deliver real results without the stress.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* 3rd row */}
      </div>
      <div className="container-fluid proces-div">
        <div className="container">
          <div className="row mt-3 ">
            <div>
              <h2
                className="expertise-div"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                <span>Our</span> Expertise
              </h2>
              <p
                className="text-center Expertise-div mt-4"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                At MS Digital Media, we combine data insights with innovative
                design to create strategies that transform brands
                <br /> and drive business growth. Our tailored solutions ensure
                impactful and measurable results in the digital landscape.
              </p>
            </div>
          </div>
          <div className="row expertise-style  p-3  mt-5 ">
            <div
              className="col-lg-3 position-relative   d-flex flex-column justify-content-center text-center "
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="100"
            >
              <div className="vertical-align-container d-flex flex-column align-items-center justify-content-center">
                <div className="image-div1 mt-2">
                  <img
                    className="img-fluid discover-img"
                    src={discover}
                    alt="imag"
                  />
                </div>
                <h5 className="mt-2 section-text">DISCOVER</h5>
                <p className="Experties-text">
                  Introduce our brand to new customers using social media,
                  search engines, and content marketing.
                </p>
              </div>
            </div>
            <div
              className="col-lg-3 position-relative  text-center mt-5"
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="100"
            >
              {/* <div className="discover-text">
                <p>2</p>
              </div> */}
              <div className=" vertical-align-container d-flex flex-column align-items-center justify-content-center">
                <div className="image-div1 mt-2">
                  <img
                    className="img-fluid discover-img"
                    src={laptop}
                    alt="imag"
                  />
                </div>
                <h5 className="mt-2 section-text">STRATEGIES</h5>
                <p className="Experties-text">
                  Develop effective plans to reach your target audience, boost
                  engagement
                </p>
              </div>
            </div>
            <div
              className="col-lg-3 position-relative   text-center"
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="100"
            >
              {/* <div className="discover-text">
                <p>3</p>
              </div> */}
              <div className=" vertical-align-container d-flex flex-column align-items-center justify-content-center">
                <div className="image-div1 mt-2">
                  <img
                    className="img-fluid discover-img"
                    src={brush}
                    alt="imag"
                  />
                </div>
                <h5 className="mt-2 section-text">CREATIVE</h5>
                <p className="Experties-text">
                  A strategically selected term that enhances SEO and resonates
                  deeply with your audience, sparking interest and action.
                </p>
              </div>
            </div>
            <div
              className="col-lg-3 position-relative  text-center mt-5"
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="100"
            >
              {/* <div className="discover-text">
                <p>4</p>
              </div> */}
              <div className=" vertical-align-container d-flex flex-column align-items-center justify-content-center">
                <div className="image-div1 mt-2">
                  <img
                    className="img-fluid discover-img"
                    src={target}
                    alt="imag"
                  />
                </div>
                <h5 className="section-text mt-2">LAUNCH</h5>
                <p className="Experties-text">
                  Make a memorable first impression that grabs attention and
                  motivates action.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row calltoaction">
          <div className="col-lg-12 text-center">
            <h1
              className="text-center text-white mt-5 calltoaction-text "
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="100"
            >
              Trouble finding leads and growing your reach?
              <br /> Let us help you ....
            </h1>
            <button
              className="mt-5 calltoaction-btn "
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="100"
            >
              <Link
                to="https://wa.me/message/WYW6B2GTORZZB1"
                className="booklink text-white"
              >
                {" "}
                Let's Connect
              </Link>
            </button>
          </div>
        </div>
      </div>
      <div className="container-fluid brand-container">
        <div className="container  ">
          <h2
            className="text-center brand-text p-5"
            data-aos="fade-up"
            data-aos-duration="800"
            data-aos-delay="100"
          >
            Create Your Unique Brand Today!
          </h2>

          <div className="row mt-5">
            <div
              className="col-lg-4 "
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="100"
            >
              <div className="brand-div mt-3">
                <h4 className="text-center">Social Media Marketing</h4>
                <p className="mt-4">
                  {" "}
                  Utilizing platforms like Facebook, Instagram, and LinkedIn to
                  connect with your audience, build brand awareness, and drive
                  engagement through strategic content and targeted advertising.
                </p>
                <Link to="/socialmediamarketing" className="view-btn">
                  View More <i class="fa-solid fa-arrow-right"></i>
                </Link>
              </div>
            </div>
            <div
              className="col-lg-4"
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="100"
            >
              <div className="brand-div mt-3">
                <h4 className="text-center">SEO</h4>
                <p className="mt-4">
                  Enhance your website's visibility on search engines like
                  Google through strategic optimization techniques. Improve
                  rankings, attract organic traffic, and increase online
                  presence effectively.
                </p>
                <Link to="/seo" className="view-btn">
                  View More <i class="fa-solid fa-arrow-right"></i>
                </Link>
              </div>
            </div>
            <div
              className="col-lg-4"
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="100"
            >
              <div className="brand-div mt-3">
                <h4 className="text-center ani-text">
                  Social Media Management
                </h4>
                <p className="mt-4">
                  Streamline your online presence across platforms like
                  Facebook, Twitter, and Instagram. Engage your audience,
                  enhance brand awareness, and drive growth with crafted content
                  and strategic campaigns.
                </p>
                <Link to="/SocialMediaManagement" className="view-btn ">
                  View More <i class="fa-solid fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div
              className="col-lg-4"
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="100"
            >
              {" "}
              <div className="brand-div mt-3">
                <h4 className="text-center">Personal Branding</h4>
                <p className="mt-4">
                  Cultivating a unique identity and reputation that reflects
                  your expertise, values, and personality. Build credibility,
                  influence, and opportunities through strategic storytelling
                  and consistent messaging across digital platforms.
                </p>
                <Link to="/PersonalBranding" className="view-btn">
                  View More <i class="fa-solid fa-arrow-right"></i>
                </Link>
              </div>
            </div>
            <div
              className="col-lg-4"
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="100"
            >
              {" "}
              <div className="brand-div mt-3">
                <h4 className="text-center">Web Development</h4>
                <p className="mt-4">
                  Crafting dynamic and user-friendly websites that meet your
                  business objectives. From design to deployment, we specialize
                  in creating responsive, scalable, and secure web solutions
                  tailored to enhance your online presence.
                </p>
                <Link to="/WebDevelopement" className="view-btn">
                  View More <i class="fa-solid fa-arrow-right"></i>
                </Link>
              </div>
            </div>
            <div
              className="col-lg-4"
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="100"
            >
              {" "}
              <div className="brand-div mt-3 ">
                <h4 className="text-center head">Copy Writing</h4>
                <p className="mt-4">
                  Copywriting is crafting persuasive text to promote products or
                  services, aiming to engage and drive specific actions from the
                  target audience. It combines creativity and strategic thinking
                  to communicate a brand's value effectively
                </p>
                <Link to="/CopyWriting" className="view-btn">
                  View More <i class="fa-solid fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* waveAnimation */}
      <div className="container-fluid">
        <div className="row mt-4">
          <div className="col-lg-12 ">
            <div class="header">
              <div
                class="inner-header wave-headcontent"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                <h1>Are You Struggling to find Quality Customers?</h1>
                <h5>Just One Message</h5>
                <button className="mt-3">
                  {" "}
                  <Link
                    className="text-white booklink"
                    to="https://wa.me/message/WYW6B2GTORZZB1"
                  >
                    Let's Talk
                  </Link>
                </button>
              </div>

              <div>
                <svg
                  className="waves"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  viewBox="0 24 150 28"
                  preserveAspectRatio="none"
                  shapeRendering="auto"
                >
                  <defs>
                    <path
                      id="gentle-wave"
                      d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
                    />
                  </defs>
                  <g class="parallax">
                    <use
                      xlinkHref="#gentle-wave"
                      x="48"
                      y="0"
                      fill="rgba(255,255,255,0.7"
                    />
                    <use
                      xlinkHref="#gentle-wave"
                      x="48"
                      y="3"
                      fill="rgba(255,255,255,0.5)"
                    />
                    <use
                      xlinkHref="#gentle-wave"
                      x="48"
                      y="5"
                      fill="rgba(255,255,255,0.3)"
                    />
                    <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Carousel />
      <Footer />
      <Speedbutton />
    </>
  );
};
