import React from "react";
import "./Seo.css";
import { Link } from "react-router-dom";
import Typewriter from "typewriter-effect";
import seo from "../image/Banner-Image-seo.jpg";
import Footer from "../Footer/Footer";
import Speedbutton from "../SpeedButton/Speedbutton";
const Seo = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row SMM-div">
          <div className="col-lg-3">
            {/* <div className="mt-5 return-link">
              <Link to="/" className="servicelinks">
                Home
              </Link>
              <span>/SEO</span>
            </div> */}
          </div>
          <div className="col-lg-6  smm-banner">
            <div id="app" className="mt-5">
              <Typewriter
                options={{
                  loop: true,
                }}
                onInit={(typewriter) => {
                  typewriter
                    .typeString("SEARCH ENGINE OPTIMIZATION")
                    .pauseFor(2500)
                    .deleteAll()

                    .start();
                }}
              />
            </div>
          </div>
          <div className="col-lg-3"></div>
        </div>
      </div>
      <div className="container-fluid service-container">
        <div className="container">
          <div className="row  seo-div">
            <div className="col-lg-6">
              <img
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
                className="mt-3 rounded-circle  zoom-on-hover image-fluid "
                src={seo}
                alt="logo"
                height={400}
                width={400}
              />
            </div>
            <div className="col-lg-6">
              <div>
                <h5
                  className="seo-head mt-5"
                  data-aos="fade-up"
                  data-aos-duration="800"
                  data-aos-delay="100"
                >
                  BOOST YOUR DIGITAL PROFILE
                </h5>
                <h2
                  className=""
                  data-aos="fade-up"
                  data-aos-duration="800"
                  data-aos-delay="100"
                >
                  SEARCH ENGINE OPTIMIZATION
                </h2>
                <p
                  className="seo-para"
                  data-aos="fade-up"
                  data-aos-duration="800"
                  data-aos-delay="100"
                >
                  At MS Digital Media, we understand the crucial role that
                  search engine optimization (SEO) plays in the success of your
                  online presence. SEO is the art and science of enhancing your
                  website to rank higher in search engine results organically,
                  making it more visible to potential customers searching for
                  products or services like yours.
                </p>
                <p
                  data-aos="fade-up"
                  data-aos-duration="800"
                  data-aos-delay="100"
                >
                  From keyword analysis and on-site optimization to technical
                  SEO and content strategy, we provide a complete range of SEO
                  services customized to your unique industry and objectives.
                  Our data-centric approach guarantees that every SEO plan is
                  supported by insights and analytics, enabling us to make
                  informed choices that deliver measurable outcomes
                </p>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <h2
              className="text-center seo-head1 "
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="100"
            >
              Boost Your Online Visibility with These Essential SEO Tips!
            </h2>
            <div
              className="col-lg-12 d-flex justify-content-center gap-5 product-list "
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="100"
            >
              <div className="mt-3">
                <div className="d-flex gap-2 align-items-center ">
                  <i class="fa-regular fa-circle-check"></i>{" "}
                  <h5 className="mt-1">Keyword Research</h5>
                </div>
                <div className="d-flex gap-2 align-items-center mt-3 ">
                  <i class="fa-regular fa-circle-check"></i>{" "}
                  <h5 className="mt-1">On-Page Optimization</h5>
                </div>
                <div className="d-flex gap-2 align-items-center mt-3 ">
                  <i class="fa-regular fa-circle-check"></i>{" "}
                  <h5 className="mt-1">SEO Strategy</h5>
                </div>
              </div>
              <div className="mt-3">
                <div className="d-flex gap-2 align-items-center ">
                  <i class="fa-regular fa-circle-check"></i>{" "}
                  <h5 className="mt-1">Content Quality</h5>
                </div>
                <div className="d-flex gap-2 align-items-center mt-3 ">
                  <i class="fa-regular fa-circle-check"></i>{" "}
                  <h5 className="mt-1">Backlinks</h5>
                </div>
                <div className="d-flex gap-2 align-items-center mt-3 ">
                  <i class="fa-regular fa-circle-check"></i>{" "}
                  <h5 className="mt-1">Content Marketing</h5>
                </div>
              </div>
              <div className="mt-3">
                <div className="d-flex gap-2 align-items-center ">
                  <i class="fa-regular fa-circle-check"></i>{" "}
                  <h5 className="mt-1">Technical SEO</h5>
                </div>
                <div className="d-flex gap-2 align-items-center  mt-3 ">
                  <i class="fa-regular fa-circle-check"></i>{" "}
                  <h5 className="mt-1">Local SEO</h5>
                </div>
                <div className="d-flex gap-2 align-items-center mt-3 ">
                  <i class="fa-regular fa-circle-check"></i>{" "}
                  <h5 className="mt-1">Analytics and Reporting</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5 improve-text">
            <div className="col-lg-12">
              <h2
                className="text-center"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                HOW SEO IMPROVES YOUR BUSINESS{" "}
              </h2>
              <p
                className="text-center"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                Search Engine Optimization (SEO) is a vital digital marketing
                strategy that can significantly enhance your business's online
                presence, credibility, and overall success. Here's a detailed
                look at how SEO can benefit your business
              </p>
              <p
                className="seo-para"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                SEO helps your website rank higher in search engine results
                pages (SERPs). This increased visibility means that more
                potential customers will find your business when they search for
                relevant keywords. Higher rankings lead to greater exposure,
                ensuring your brand reaches a broader audienc
              </p>
              <p
                className="seo-para"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                With improved search engine rankings, your website will attract
                more organic traffic. SEO focuses on optimizing various aspects
                of your site, including content, keywords, and meta tags, to
                make it more appealing to search engines. More traffic means
                more opportunities to convert visitors into customers.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Speedbutton />
    </>
  );
};

export default Seo;
