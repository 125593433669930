import React from "react";
import "./WebDevelopement.css";
import { Link } from "react-router-dom";
import Typewriter from "typewriter-effect";
import logo from "../image/webdevelop.png";
import Footer from "../Footer/Footer";
import logo1 from "../image/staticswebsite-2.jpg";
import dynamicimage from "../image/dynamics.jpg";
import ecommarace from "../image/ecommarce.png";
import Speedbutton from "../SpeedButton/Speedbutton";
const WebDevelopement = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row SMM-div">
          <div className="col-lg-3">
            {/* <div className="mt-5 return-link">
              <Link to="/" className="servicelinks ">
                Home
              </Link>
              <span>/Web DevelopMent</span>
            </div> */}
          </div>
          <div className="col-lg-6  smm-banner ">
            <div id="app" className="mt-5">
              <Typewriter
                options={{
                  loop: true,
                }}
                onInit={(typewriter) => {
                  typewriter
                    .typeString("WEB DEVELOPMENT")
                    .pauseFor(2500)
                    .deleteAll()

                    .start();
                }}
              />
            </div>
          </div>
          <div className="col-lg-3"></div>
        </div>
      </div>
      <div className="container-fluid service-container">
        <div className="container sdf">
          <div className="row">
            <div className="col-lg-6">
              <img
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
                className="mt-5 rounded-circle static-image  zoom-on-hover"
                src={logo}
                alt="image"
                height={400}
                width={400}
              />
            </div>
            <div className="col-lg-6 webdevelop">
              <h6
                className="mt-4 seo-head"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                NEXT-GENERATION WEB SOLUTIONS
              </h6>
              <h2
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                WEB DEVELOPMENT
              </h2>
              <p
                className="web-text"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                MS Digital Media is a premier provider of top-notch website
                development services, offering cost-effective, high-performance
                solutions tailored to each client's unique needs. Our committed
                team ensures strict quality standards are upheld through
                expertise in advanced technologies, continually pushing the
                limits of web development.
              </p>
              <p
                className="web-text"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                {" "}
                Our reputation is founded on a steadfast commitment to
                innovation in web application development. We blend
                state-of-the-art web technology with creative proficiency,
                working closely with clients across various industries to
                achieve tangible results and enhance online sales. Contact us
                today to partner with a trusted website design company!
              </p>
            </div>
          </div>
          <div className="row mt-3">
            <h2
              className="seo-head1"
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="100"
            >
              Transform Your Online Presence with Expert Web Development
              Services!
            </h2>
            <div
              className="col-lg-12 d-flex justify-content-center gap-5  product-list "
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="100"
            >
              <div className="mt-3">
                <div className="d-flex gap-2 align-items-center ">
                  <i class="fa-regular fa-circle-check"></i>{" "}
                  <h5 className="mt-1">Expertise in Technologies</h5>
                </div>
                <div className="d-flex gap-2 align-items-center mt-3 ">
                  <i class="fa-regular fa-circle-check"></i>{" "}
                  <h5 className="mt-1">User Friendly</h5>
                </div>
                <div className="d-flex gap-2 align-items-center mt-3 ">
                  <i class="fa-regular fa-circle-check"></i>{" "}
                  <h5 className="mt-1">Security and Compliance</h5>
                </div>
              </div>
              {/* <div className="mt-3">
                <div className="d-flex gap-2 align-items-center ">
                  <i class="fa-regular fa-circle-check"></i>{" "}
                  <h5 className="mt-1">Content Quality</h5>
                </div>
                <div className="d-flex gap-2 align-items-center mt-3 ">
                  <i class="fa-regular fa-circle-check"></i>{" "}
                  <h5 className="mt-1">Backlinks</h5>
                </div>
                <div className="d-flex gap-2 align-items-center mt-3 ">
                  <i class="fa-regular fa-circle-check"></i>{" "}
                  <h5 className="mt-1">Content Marketing</h5>
                </div>
              </div> */}
              <div className="mt-3">
                <div className="d-flex gap-2 align-items-center ">
                  <i class="fa-regular fa-circle-check"></i>{" "}
                  <h5 className="mt-1">Affordable Price</h5>
                </div>
                <div className="d-flex gap-2 align-items-center  mt-3 ">
                  <i class="fa-regular fa-circle-check"></i>{" "}
                  <h5 className="mt-1">Ecommerce Website</h5>
                </div>
                <div className="d-flex gap-2 align-items-center mt-3 ">
                  <i class="fa-regular fa-circle-check"></i>{" "}
                  <h5 className="mt-1">Responsive Website</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <h3
              className="text-center web-head"
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="100"
            >
              OUR WEB DEVELOPMENT SERVICES
            </h3>
            <p
              className="text-center"
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="100"
            >
              Transforming your unique ideas into dynamic, user-centric web
              solutions that drive success and engagement.
            </p>
            <div className="col-lg-6">
              {" "}
              <img
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
                className="static-image mt-5 zoom-on-hover"
                src={logo1}
                alt="image"
              />
            </div>
            <div className="col-lg-6">
              {" "}
              <h2
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                STATIC WEBSITE DEVELOPMENT
              </h2>
              <p
                className="web-text"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                Ms Digital Media knows that first impressions matter, and our
                static web development services aim to make a lasting one. Our
                team thoroughly analyzes your business needs to create custom,
                user-friendly websites that truly represent your brand. Our
                static websites are designed for fast loading times and
                compatibility across different devices and browsers, ensuring a
                smooth user experience.
              </p>
              <p
                className="web-text"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                Our reputation is built on our commitment to innovation in web
                development. We use the latest technology and creativity to
                deliver real results and boost online sales for clients in
                various industries. Contact us today to work with a trusted
                website design company!
              </p>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-6">
              <h2
                className="mt-2"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                DYNAMIC WEBSITE DEVELOPMENT
              </h2>
              <p
                className="web-text"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                At Ms Digital Media, we customize our dynamic website
                development services to fit each client's unique needs. We
                specialize in creating websites that blend beautiful design with
                great functionality, ensuring an outstanding user experience.
              </p>
              <p
                className="web-text"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                A dynamic website is one that changes its content based on what
                the user does or specific conditions. Unlike a static website,
                which shows the same information to everyone, a dynamic website
                updates and displays different content in real-time. This makes
                it great for things like online stores, social networks, or any
                site that needs regular updates or personalization
              </p>
            </div>
            <div className="col-lg-6">
              <img
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
                className="static-image mt-5 zoom-on-hover"
                src={dynamicimage}
                alt="image"
              />
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-6">
              <img
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
                className="static-image  zoom-on-hover"
                src={ecommarace}
                alt="image"
              />
            </div>

            <div className="col-lg-6">
              <h2
                className="mt-2"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                E-COMMERCE WEBSITE DEVELOPMENT
              </h2>
              <p
                className="web-text"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                At Ms Digital Media, we are your trusted partner for e-commerce
                success. Our e-commerce website development services, including
                Shopify solutions, aim to help you achieve your online goals.
                Our experienced team creates beautiful and functional online
                stores tailored to your needs.
              </p>
              <p
                className="web-text"
                data-aos="fade-up"
                data-aos-duration="800"
                data-aos-delay="100"
              >
                An eCommerce website is a site where you can buy and sell
                products or services online. It includes features like product
                listings, a shopping cart, and secure payment options, allowing
                customers to browse items, make purchases, and complete
                transactions directly through the website.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Speedbutton />
    </>
  );
};

export default WebDevelopement;
